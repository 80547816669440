import { createWebHistory, createRouter } from "vue-router";

import store from '../store';

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import('@/pages/index/home.vue'),
  },

  // Eventos Pages
  {
    path: "/eventos",
    name: "eventos",
    component: () => import('@/pages/innerpages/eventos/index.vue'),
  },
  {
    path: "/eventos/:codigo_evento",
    name: "eventos.detalle",
    component: () => import('@/pages/innerpages/eventos/show.vue'),
  },
  {
    path: "/:codigo_evento",
    name: "eventos.detalle",
    component: () => import('@/pages/innerpages/eventos/show.vue'),
  },

  // Tickets Pages
  
  {
    path: '/ticket/:codigo_ticket',
    name: 'ticket',
    component: () => import('@/pages/innerpages/tickets/show.vue'),
  },
  // About us
  {
    path: "/nosotros",
    name: "nosotros",
    component: () => import('@/pages/innerpages/nosotros.vue'),
  },
  {
    path: "/faqs",
    name: "faqs",
    component: () => import('@/pages/innerpages/faqs.vue'),
  },
  {
    path: "/support",
    name: "support",
    component: () => import('@/pages/innerpages/support.vue'),
  },
  {
    path: "/contacto",
    name: "contacto",
    component: () => import('@/pages/innerpages/contacto.vue'),
  },
  {
    path: "/servicios",
    name: "servicios",
    component: () => import('@/pages/innerpages/servicios/servicios.vue'),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import('@/pages/innerpages/utility/terms.vue'),
  },

  // Auth Pages
  {
    path: "/login",
    name: "login",
    component: () => import('@/pages/innerpages/auth-pages/login.vue'),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import('@/pages/innerpages/auth-pages/signup.vue'),
  },
  {
    path: "/signup-success",
    name: "signup-success",
    component: () => import('@/pages/innerpages/auth-pages/signup-success.vue'),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import('@/pages/innerpages/auth-pages/forgot-password.vue'),
  },

  // My Account Pages
  {
    path: "/perfil",
    name: "perfil",
    component: () => import('@/pages/innerpages/perfil/show.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/perfil/participantes/:codigo_participante",
    name: "perfil.participantes.show",
    component: () => import('@/pages/innerpages/perfil/eventos/show.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/perfil/participantes/:codigo_participante/pago",
    name: "perfil.participantes.landing",
    component: () => import('@/pages/innerpages/perfil/eventos/landing.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/configuracion",
    name: "configuracion",
    component: () => import('@/pages/innerpages/perfil/configuracion.vue'),
    meta: { requiresAuth: true }
  },




  {
    path: "/index-two",
    name: "index-two",
    component: () => import('@/pages/index/index-two.vue'),
  },
  {
    path: "/index-three",
    name: "index-three",
    component: () => import('@/pages/index/index-three.vue'),
  },
  {
    path: "/index-four",
    name: "index-four",
    component: () => import('@/pages/index/index-four.vue'),
  },
  {
    path: "/index-five",
    name: "index-five",
    component: () => import('@/pages/index/index-five.vue'),
  },
  {
    path: "/grid",
    name: "grid",
    component: () => import('@/pages/innerpages/listing/tour-grid/grid.vue'),
  },
  {
    path: "/grid-right-sidebar",
    name: "grid-right-sidebar",
    component: () => import('@/pages/innerpages/listing/tour-grid/grid-right-sidebar.vue'),
  },
  {
    path: "/grid-left-sidebar",
    name: "grid-left-sidebar",
    component: () => import('@/pages/innerpages/listing/tour-grid/grid-left-sidebar.vue'),
  },
  {
    path: "/list",
    name: "list",
    component: () => import('@/pages/innerpages/listing/tour-list/list.vue'),
  },
  {
    path: "/list-left-sidebar",
    name: "list-left-sidebar",
    component: () => import('@/pages/innerpages/listing/tour-list/list-left-sidebar.vue'),
  },
  {
    path: "/list-right-sidebar",
    name: "list-right-sidebar",
    component: () => import('@/pages/innerpages/listing/tour-list/list-right-sidebar.vue'),
  },
  {
    path: "/tour-detail-one",
    name: "tour-detail-one",
    component: () => import('@/pages/innerpages/listing/tour-detail/tour-detail-one.vue'),
  },
  {
    path: "/tour-detail-two",
    name: "tour-detail-two",
    component: () => import('@/pages/innerpages/listing/tour-detail/tour-detail-two.vue'),
  },
  {
    path: "/user-payment",
    name: "user-payment",
    component: () => import('@/pages/innerpages/my-account/user-payment.vue'),
  },
  {
    path: "/user-invoice",
    name: "user-invoice",
    component: () => import('@/pages/innerpages/my-account/user-invoice.vue'),
  },
  {
    path: "/user-social",
    name: "user-social",
    component: () => import('@/pages/innerpages/my-account/user-social.vue'),
  },
  {
    path: "/user-notification",
    name: "user-notification",
    component: () => import('@/pages/innerpages/my-account/user-notification.vue'),
  },
  {
    path: "/lock-screen",
    name: "lock-screen",
    component: () => import('@/pages/innerpages/auth-pages/lock-screen.vue'),
  },
  {
    path: "/helpcenter-faqs",
    name: "helpcenter-faqs",
    component: () => import('@/pages/innerpages/helpcenter/helpcenter-faqs.vue'),
  },
  {
    path: "/helpcenter-guides",
    name: "helpcenter-guides",
    component: () => import('@/pages/innerpages/helpcenter/helpcenter-guides.vue'),
  },
  {
    path: "/helpcenter-support",
    name: "helpcenter-support",
    component: () => import('@/pages/innerpages/helpcenter/helpcenter-support.vue'),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import('@/pages/innerpages/utility/privacy.vue'),
  },
  {
    path: "/comingsoon",
    name: "comingsoon",
    component: () => import('@/pages/innerpages/special-pages/comingsoon.vue'),
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import('@/pages/innerpages/special-pages/maintenance.vue'),
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () => import('@/pages/innerpages/blog/blogs.vue'),
  },
  {
    path: "/blog-standard",
    name: "blog-standard",
    component: () => import('@/pages/innerpages/blog/blog-standard.vue'),
  },
  {
    path: "/blog-detail",
    name: "blog-detail",
    component: () => import('@/pages/innerpages/blog/blog-detail.vue'),
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import('@/pages/innerpages/special-pages/404.vue'),
  },
  {//reset-password
    path: "/reset-password",
    name: "reset-password",
    component: () => import('@/pages/innerpages/auth-pages/reset-password.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const ignorePathList = ['/login', '/signup', '/forgot-password', '/reset-password', '/signup-success', '/404'];
  //if previous route is not in ignore list then save it (ignore params)
  if (!ignorePathList.includes(from.fullPath.split('?')[0])) {
    localStorage.setItem('previousRoute', from.fullPath);
  }


  if (to.meta && to.meta.requiresAuth) {
    if (!store.getters.isAuthenticated) {
      next({
        name: 'login',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;